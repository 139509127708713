import React, { useState, useEffect } from 'react';
import './App.css';
import { FaBars } from 'react-icons/fa';
import AnimatedIntro from './AnimatedIntro';

function App() {
  const [showGallery, setShowGallery] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('looks');
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [showNavItems, setShowNavItems] = useState(false);

  const images = {
    looks: [
      '/image1.jpeg',
      '/image2.jpeg',
      '/image1.jpeg',
      '/image4.jpeg',
      '/image1.jpeg',
      '/image2.jpeg',
      '/image4.jpeg',
      '/image1.jpeg',
      '/image2.jpeg',
      '/image4.jpeg',
    ],
    beauty: [
      '/image3.jpeg',
      '/image5.jpeg',
      '/image6.jpeg',
      '/image3.jpeg',
      '/image5.jpeg',
      '/image6.jpeg',
      '/image3.jpeg',
      '/image5.jpeg',
      '/image6.jpeg',
      '/image3.jpeg',
      '/image5.jpeg',
      '/image6.jpeg',
      '/image3.jpeg',
    ],
  };

  useEffect(() => {
    const allImages = [...images.looks, ...images.beauty];
    let loadedCount = 0;

    const imageLoadHandler = (src) => {
      loadedCount++;
      console.log(`Image loaded: ${src}`);
      if (loadedCount === allImages.length) {
        setImagesLoaded(true);
        console.log("All images loaded")
      }
    };

    allImages.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => imageLoadHandler(src);
      img.onerror = () => console.log("Error in",src);
    });
  }, []);

  const renderGallery = (category) => {
    const rows = [];
    for (let i = 0; i < images[category].length; i += 3) {
      const rowImages = images[category].slice(i, i + 3);
      rows.push(
        <div className="gallery-row" key={i}>
          {rowImages.map((image, index) => (
            <div className="gallery-item" key={index}>
              <img src={image} alt={`gallery ${index}`} className="gallery-image" />
            </div>
          ))}
        </div>
      );
    }
    return rows;
  };

  const handleIntroFinish = () => {
    if (imagesLoaded) {
      console.log("Set true")
      setShowGallery(true);
    }
  };

  const toggleNavItems = () => {
    setShowNavItems(!showNavItems);
  };

  return (
    <div className="App">
      {showGallery && imagesLoaded ? (
        <>
          <header className="App-header">
            <div className="header-left">Kunjan Vaghasiya</div>
            <div className="header-middle">
              <div
                className={`header-button ${selectedCategory === 'looks' ? '' : 'unselected'}`}
                onClick={() => setSelectedCategory('looks')}
              >
                Looks
              </div>
              <div
                className={`header-button ${selectedCategory === 'beauty' ? '' : 'unselected'}`}
                onClick={() => setSelectedCategory('beauty')}
              >
                Beauty
              </div>
            </div>
            <div className="header-right">
              <FaBars className="hamburger-icon" onClick={toggleNavItems} />
            </div>
          </header>
          <div className="App-body">
            {renderGallery(selectedCategory)}
          </div>
          <footer className="App-footer">
            Powered by <br/>
            <a href="https://whiskerWeb.studio">WhiskerWeb.studio</a>
          </footer>
        </>
      ) : (
        <AnimatedIntro onFinish={() => setShowGallery(true)} imagesLoaded={imagesLoaded} />
      )}
      {showNavItems && (
        <div className="nav-items">
          <ul>
            <li>TikTok</li>
            <li>Instagram</li>
            <li>Appointment</li>
          </ul>
        </div>
      )}
      {showNavItems && <div className="purple-overlay" onClick={toggleNavItems}></div>}
    </div>
  );
}

export default App;
