import React, { useEffect } from 'react';
import './AnimatedIntro.css';

const AnimatedIntro = ({ onFinish, imagesLoaded }) => {
  useEffect(() => {
    const minDuration = 6000; // Minimum duration in milliseconds
    const start = Date.now();

    const checkCompletion = () => {
      const elapsed = Date.now() - start;
      if (imagesLoaded && elapsed >= minDuration) {
        onFinish();
      } else {
        const remainingTime = minDuration - elapsed;
        const timer = setTimeout(() => {
          if (imagesLoaded) {
            onFinish();
          }
        }, remainingTime);
        return () => clearTimeout(timer);
      }
    };

    if (imagesLoaded) {
      checkCompletion();
    } else {
      const timer = setTimeout(() => {
        if (imagesLoaded) {
          checkCompletion();
        }
      }, minDuration);
      return () => clearTimeout(timer);
    }
  }, [onFinish, imagesLoaded]);

  const handleButtonClick = (e) => {
    e.preventDefault();
    onFinish();
  };

  return (
    <div className="untitled">
      <div className="untitled__slides">
        <div className="untitled__slide">
          <div className="untitled__slideBg"></div>
          <div className="untitled__slideContent">
            <span>Kunjan</span> 
            <span>Vaghasiya</span>
            <a className="button" href="#" onClick={handleButtonClick}>Check Profile</a>
          </div>
        </div>
      </div>
      <div className="untitled__shutters"></div>
    </div>
  );
};

export default AnimatedIntro;
